<template>
  <div class="flex flex-col mt-4 gap-5 h-screen">
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="thead"> </template>
      <template slot="tbody">
        <vs-tr v-for="(tr, indextr) in responseData.creditNotes" :key="indextr">
          <vs-td>
            <div class="flex flex-row gap-3">
              <vx-tooltip text="View" style="width: fit-content">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-eye"
                  @click="navigateToForm(tr.ID, 'view')"
                />
              </vx-tooltip>
            </div>
          </vs-td>
          <vs-td>
            {{ tr.Code }}
          </vs-td>
          <vs-td>
            {{ dateFormated(tr.Date) }}
          </vs-td>
          <vs-td>
            Code : {{ tr.CustomerCode }} <br />
            Name : {{ tr.CustomerName }}
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      responseData: {},
      header: [
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "action",
        },
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "credit_note_number",
        },
        {
          text: "Date",
          value: "credit_note_date",
        },
        {
          text: "Customer",
          value: "supplier",
        },
      ],
      params: {
        length: 10,
        page: 1,
        search: "",
        sort: "credit_notes.id",
        order: "desc",
        status: 0,
      },
      checkedCreditNote: [],
      checkedCreditNoteID: [],
      checkedAll: false,
    };
  },
  methods: {
    reloadData(params) {
      this.$vs.loading();
      params = {
        ...params,
        status: 7,
      };
      this.$http
        .get("api/v1/credit-note/temporary", { params })
        .then((response) => {
          if (response.code < 299) {
            this.responseData = response.data;
          } else {
            this.responseData = {};
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    dateFormated(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    navigateToForm(id = null, action = "edit") {
      if (!id) {
        this.$router.push({
          name: "cn_temporary.create",
        });
        return;
      }

      if (action === "edit") {
        this.$router.push({
          name: "cn_temporary.edit",
          params: { id: btoa(id) },
        });
      } else if (action === "view") {
        this.$router.push({
          name: "cn_temporary.view",
          params: { id: btoa(id) },
        });
      }
    },
  },
};
</script>
