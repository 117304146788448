<template>
  <div class="flex flex-col mt-4 gap-5 h-screen">
    <!-- <div class="flex gap-3">
      <vs-button @click="handleConfirm">Confirm</vs-button>
      <vs-button @click="navigateToForm(null)">Create</vs-button>
    </div> -->
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <!-- <template slot="thead">
        <th width="15%">
          <vs-checkbox v-model="checkedAll">Check All</vs-checkbox>
        </th>
      </template> -->
      <template slot="tbody">
        <vs-tr v-for="(tr, indextr) in responseData.creditNotes" :key="indextr">
          <!-- <vs-td>
            <vs-checkbox
              @click="addChecked(tr.ID)"
              :checked="isChecked(tr.ID)"
            />
          </vs-td> -->
          <vs-td>
            <div class="flex flex-row gap-3">
              <!-- <vx-tooltip text="Edit" style="width: fit-content">
                <vs-button
                  color="warning"
                  type="line"
                  icon-pack="feather"
                  icon="icon-edit"
                  @click="navigateToForm(tr.ID, 'edit')"
                />
              </vx-tooltip> -->
              <vx-tooltip text="View" style="width: fit-content">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-eye"
                  @click="navigateToForm(tr.ID, 'view')"
                />
              </vx-tooltip>
            </div>
          </vs-td>
          <vs-td>
            {{ tr.Code }}
          </vs-td>
          <vs-td>
            {{ dateFormated(tr.Date) }}
          </vs-td>
          <vs-td>
            Code : {{ tr.CustomerCode }} <br />
            Name : {{ tr.CustomerName }}
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      responseData: {},
      header: [
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "action",
        },
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "credit_note_number",
        },
        {
          text: "Date",
          value: "credit_note_date",
        },
        {
          text: "Customer",
          value: "supplier",
        },
      ],
      params: {
        length: 10,
        page: 1,
        search: "",
        sort: "credit_notes.id",
        order: "desc",
        status: 0,
      },
      checkedCreditNote: [],
      checkedCreditNoteID: [],
      checkedAll: false,
    };
  },
  methods: {
    reloadData(params) {
      this.$vs.loading();
      params = {
        ...params,
        status: 3,
      };
      this.$http
        .get("api/v1/credit-note/temporary", { params })
        .then((response) => {
          if (response.code < 299) {
            this.responseData = response.data;
          } else {
            this.responseData = {};
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    navigateToForm(id = null, action = "edit") {
      if (!id) {
        this.$router.push({
          name: "cn_temporary.create",
        });
        return;
      }

      if (action === "edit") {
        this.$router.push({
          name: "cn_temporary.edit",
          params: { id: btoa(id) },
        });
      } else if (action === "view") {
        this.$router.push({
          name: "cn_temporary.view",
          params: { id: btoa(id) },
        });
      }
    },
    dateFormated(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    addChecked(id) {
      if (!this.checkedCreditNoteID.includes(id)) {
        this.checkedCreditNoteID.push(id);
      } else {
        const index = this.checkedCreditNoteID.indexOf(id);
        this.checkedCreditNoteID.splice(index, 1);
      }
    },
    isChecked(id) {
      return this.checkedCreditNoteID.includes(id);
    },
    handleConfirm() {
      if (this.checkedCreditNoteID.length === 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select at least one credit note",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
        });
        return;
      }

      this.$vs.loading();

      this.$http
        .patch("api/v1/credit-note/temporary/confirm", {
          credit_note_ids: this.checkedCreditNoteID,
        })
        .then((response) => {
          if (response.code < 299) {
            this.$vs.notify({
              title: "Success",
              text: response.message,
              color: "success",
              iconPack: "feather",
              icon: "icon-check-circle",
              position: "top-right",
            });
            this.reloadData(this.params);
          } else {
            this.$vs.notify({
              title: "Error",
              text: response.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
            });
          }
          this.$vs.loading.close();
          this.checkedCreditNoteID = [];
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: error.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
        });
    },
  },
  watch: {
    checkedAll(value) {
      if (value) {
        this.checkedCreditNoteID = this.responseData.creditNotes.map(
          (invoice) => invoice.ID
        );
      } else {
        this.checkedCreditNoteID = [];
      }
    },
  },
};
</script>
